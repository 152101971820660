import { ReduxState, Record, Identifier } from 'react-admin';

export type ThemeName = 'light' | 'dark';

export interface TemplateDisplay {
    id: string;
    templateName: string;
}

export interface AppState extends ReduxState {
    theme: ThemeName;
}

export const Roles = {
    'Admin': 'Admin',
    'Scheduler': 'Scheduler',
    'ReadOnly': 'ReadOnly',
    'Customer': 'Customer',
}

export const ReadOnlyRoles: Array<string> = [
    Roles.Customer, Roles.ReadOnly
]

export const Resources = {
    "Writer": "Writer",
    "Event": "Event",
    "TimeZone": "TimeZone",
    "ParsingFormat": "ParsingFormat",
    "Language": "Language",
    "FontFamily": "FontFamily",
    "Background": "Background",
    "FontSize": "FontSize",
    "TextColor": "TextColor",
    "LanguageDisplay": "LanguageDisplay",
    "User": "User",
    "Role": "Role",
    'Template': 'Template',
    'Customer': 'Customer',
}

export const GetResourceDisplayName = (resourceName: string): string =>{
    switch(resourceName.toLowerCase()){
        case 'languagedisplay':
            return 'Language';
        case 'fontsize':
            return 'Font Size';
        case 'fontfamily':
            return 'Font Family';
        case 'background':
            return 'Background';
        case 'textcolor':
            return  'Text Color';
        default:
            return 'No Matching Resouce';
    }
}

export const EventInfoName = {
    "streamTextId": "streamTextId",
    "title": "title",
    "displayTitle": 'displayTitle',
    "scheduledStartDate": "scheduledStartDate",
    "scheduledEndDate": "scheduledEndDate",
    "status": "status",
    "errors": "errors",
    "timeZone": "timeZoneId",
    "isStreamToZoom": "isStreamToZoom",
    "zoomUrl": "zoomUrl",
    "isStreamToYouTube": "isStreamToYouTube",
    "youTubeUrl": "youTubeUrl",
    "password": "password",
    "isPasswordRequired": 'isPasswordEnabled',
    "backgroundColor": "backgroundColor",
    "foreColor": "foreColor",
    "fontFamily": "fontFamily",
    "fontSize": "fontSize",
    "primaryLanguage": "primaryLanguageId",
    "translations": "translationIds",
    "duration": "scheduledDuration",
    "writer": "writerId",
    "parsingFormat": "parsingFormatId",
    "delay": "delay",
    "isPersistTranscript": 'isPersistTranscript',
    'persistTranscript': 'daysToPersist',
    'isEmailTranscript': 'isAutoEmail',
    'emailList': 'emailAddresses',
    'displayWriter': 'displayWriter',
    'zoomCorrectionBuffer': 'zoomCorrectionBuffer',
    'zoomSendOnLineCount': 'zoomSendOnLineCount',
    'zoomSendOnTimer': 'zoomSendOnTimer',
    'zoomSendOnLineFeed': 'zoomSendOnLineFeed',
    'zoomSendOnSentenceEnd': 'zoomSendOnSentenceEnd',
    'youTubeDelayUpdates': 'youTubeDelayUpdates',
    'allowCopyContent': 'allowCopyContent',
    'isWebPlayerEnabled': 'isWebPlayerEnabled',
    'isWelcomeMessageEnabled': 'isWelcomeMessageEnabled',
    'welcomeMessage': 'welcomeMessage',
    'customers': 'customerIds',
    'hasTranscripts': 'hasTranscripts',
    'actualDateTime': 'actualDateTime',
    'actualDuration': 'actualDuration',
    'viewerCount': 'viewerCount',
    'uniqueViewerCount': 'uniqueViewerCount',
    'sessionCount': 'sessionCount',
    'requestCount': 'requestCount,'
}

export const EventInfoDisplayName = {
    'delay': 'Add delay (seconds)',
    'title': 'Session Url',
    'displayTitle': 'Session Name',
    'enableWebPlayer': 'Enable web player',
    'youTubeAddDelaySeconds': 'Add delay (seconds)',
    'youTubeUrl': 'YouTube URL',
    'copyContent': 'Allow copying of content',
    'textColor': 'Text color',
    'startDate': 'Start Date (Eastern Time)',
    'endDate': 'End Date (Eastern Time)'
}

export interface EventInfo{
    id: string;
    streamTextId: string;
    title: string;
    scheduledStartDate: Date
    scheduledEndDate: Date;
    status: ValueOfStatusType;
    writers: Array<BaseKeyValueTransferWithPrimary>;
    errors: string;
    parsingFormat: BaseKeyValueTransferWithPrimary;
    timeZone: BaseKeyValueTransfer;
    isStreamToZoom: boolean;
    zoomUrl: string;
    isStreamToYouTube: boolean;
    youTubeUrl: string;
    password: string;
    languages: Array<Language>;
    isAutoEmail: boolean;
    emails: Array<string>;
    backgroundColor: string;
    foreColor: string;
    fontFamily: string;
    fontSize: string;
    duration: number;
    writer: string;
    delay: number;
}

export interface DisplayOption extends Record {
    optionId: number,
    label: string,
    value: string,
    selected: boolean,
    id: Identifier
}

export enum StatusType {
    Completed = 'Completed',
    Scheduled = 'Scheduled',
    Live = 'Live',
    Cancelled = 'Cancelled'
}

export type ValueOfStatusType = `${StatusType}`

export type StatusColor = {
    [P in ValueOfStatusType]?: string;
}

export const StatusColors: Map<ValueOfStatusType, string> = new Map<ValueOfStatusType, string>(
    [[StatusType.Live, '#90ee90'],
    [StatusType.Scheduled, '#f89406'],
    [StatusType.Completed, '#999'],
    [StatusType.Cancelled, '#FFCCCB']]
);

export interface Language extends BaseKeyValueTransfer{
    order: number;
}

export interface BaseKeyValueTransferWithPrimary extends BaseKeyValueTransfer{
    primary: boolean;
}

export interface BaseKeyValueTransfer{
    id: string;
    key: string;
    value: string;
}


export const nthIndexOfPattern = (searchString: string, searchPattern: string, occurenceToFind: number): number =>{
    const length: number = searchString.length;
    let i: number = -1;
    while(occurenceToFind-- && i++ < length){
        i= searchString.indexOf(searchPattern, i);
        if (i < 0) break;
    }
    return i;
}

export interface CaptionmaxUrl{
    url: string | null;
}

export const getApiUrl = (): string => {
    let url = sessionStorage.getItem('url');

    const returnUrl = url === null || url === '' ?
                        process.env.REACT_APP_CATPTIONMAXAPI :
                        url;

    console.log(`The value from getApiUrl is (${returnUrl}`);

    return returnUrl;
}
