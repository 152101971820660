import React, { FC } from 'react';
import { Redirect } from 'react-router';
import { startOfToday, startOfTomorrow } from 'date-fns';

const DashboardRedirect: FC = () => {
    var todayFilter = {
        scheduledStartDate_gte: startOfToday().toLocaleString(),
        scheduledStartDate_lte: startOfTomorrow().toLocaleString(),
    };
    var encodedFilter = encodeURIComponent(JSON.stringify(todayFilter))
    return (
    <Redirect to={`/Event?filter=${encodedFilter}&order=DESC&page=1&perPage=50&sort=scheduledStartDate`} />
    )
}

export default DashboardRedirect;