import Loadable from 'react-loadable';
import Loading from '../../loader/loading.component'

export const AsyncLoadTemplateList = Loadable({
    loader: () => import('./eventInfoTemplateList.component'),
    loading: Loading
});

export const AsyncLoadTemplateCreate = Loadable({
    loader: () => import('./eventInfoTemplateCreate.component'),
    loading: Loading
});

export const AsyncLoadTemplateEdit = Loadable({
    loader: () => import('./eventInfoTemplateEdit.component'),
    loading: Loading
});