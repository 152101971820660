import { getApiUrl } from './../types';
import { AuthProvider } from "ra-core";

export interface CaptionMaxAuthorizationProvider extends AuthProvider{
    getAuthToken: () => string;
    getUserId: () => string;
    getPermissionsWithoutParams: () => string;
}

const CaptionMaxAuthProvider: CaptionMaxAuthorizationProvider ={
    login: ({username, password}): Promise<any> => {
        const request = new Request(`${getApiUrl()}/user/login`, {
            method: 'POST',
            body: JSON.stringify({email: username, password: password})           ,
            headers: new Headers({ 'Content-Type': 'application/json'})
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300){
                    throw new Error(response.statusText);
                }
                var json = response.json();
                return json;
            })
            .then( auth => {                
                sessionStorage.setItem('auth', JSON.stringify(auth))
            })
    },
    checkError: (error): Promise<void> => {
        const status = error.status;
        if (status === 401 || status ===403){
            sessionStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    checkAuth: (): Promise<void> =>{
        return sessionStorage.getItem('auth')
        ? Promise.resolve()
        : Promise.reject();
    },
    logout: (): Promise<void> => {
        sessionStorage.removeItem('auth');
        return Promise.resolve();
    },
    getIdentity: () : Promise<any> =>{
        try{
            const {id, fullName } = JSON.parse(sessionStorage.getItem('auth') as string);
            return Promise.resolve({id, fullName })
        }
        catch(error){
            return Promise.reject(error);
        }
    },
    getPermissions: (): Promise<any> =>{
        try{
            const { role } = JSON.parse(sessionStorage.getItem('auth') as string);
            return role ?
                Promise.resolve(role) : 
                Promise.reject();
            
        }
        catch(error){
            return Promise.reject(error);
        }
    },
    getAuthToken: (): string =>{
        const { token } = JSON.parse(sessionStorage.getItem('auth') as string);
        return token;
    },
    getUserId: (): string =>{
        const { id } = JSON.parse(sessionStorage.getItem('auth') as string);
        return id;
    },
    getPermissionsWithoutParams: (): string =>{
        if (!sessionStorage.getItem('auth'))
            return '';
        const { role } = JSON.parse(sessionStorage.getItem('auth') as string);
        return role;
    }
}

export default CaptionMaxAuthProvider;