import React, { Component } from "react";
import { crudGetOne, UserMenu, 
        MenuItemLink, Button,
        showNotification } from 'react-admin';
import SettingsIcon from '@material-ui/icons/Settings';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { connect } from 'react-redux';

import CaptionMaxAuthProvider from "../authProvider/captionMaxAuthProvider";

import { getApiUrl, Resources, Roles } from "../types";

class CustomerUserProfileMenu extends Component<any> {
    componentDidMount() {
        this.fetchProfile();
    }

    synWriters = (notification: any) => {
        const apiUrl = `${getApiUrl()}/writer/loadexternaldata`;

        const request = new Request(apiUrl, {
            method: 'GET',
            headers: new Headers({ 'Content-Type': 'application/json'})
        });

        const startSync = async () => {
            await fetch(request)
            notification('Request to update writers sent', 'success')
        }
        startSync();
    }

    fetchProfile = () => {
        try{
            const userId = CaptionMaxAuthProvider.getUserId();
            this.props.crudGetOne(
                    // The resource
                    Resources.User,
                    // The id of the resource item to fetch
                    userId,
                    // The base path. Mainly used on failure to fetch the data
                    '/event',
                    // Wether to refresh the current view. I don't need it here
                    false
                );
        }
        catch{
            return;
        }
    };

    render() {
        const { crudGetOne, profile, showNotification, ...props } = this.props;
        const permissions = CaptionMaxAuthProvider.getPermissionsWithoutParams();
        try{
            return (
                <UserMenu key='userProfileUserMenuOption' label={profile ? `${profile.firstName} ${profile.lastName}` : ''} {...props}>
                    
                    <MenuItemLink
                        key='userProfileMenuItemLink'
                        to={`/user/${CaptionMaxAuthProvider.getUserId()}`}
                        primaryText="User profile"
                        leftIcon={<SettingsIcon />}
                    />
                    { permissions === Roles.Admin ?
                        <div>
                            <Button variant='text'
                                color='default'
                                onClick={() => this.synWriters(showNotification)}
                                startIcon={ <SyncAltIcon /> } label='Sync Writers'/>
                        </div> : null
                    }
                    
                </UserMenu>
            );
        }
        catch{
            return null;
        }
    }
}

const mapStateToProps = (state: any) => {
    const resource = Resources.User;
    try{
        var id = CaptionMaxAuthProvider.getUserId();
        return {
            profile: state.admin.resources[resource]
                ? state.admin.resources[resource].data[id]
                : null
        };
    }
    catch{
        return null;
    }

    
};

const UserProfileMenu = connect(
    mapStateToProps,
    { crudGetOne, showNotification }
)(CustomerUserProfileMenu);
export default UserProfileMenu;



