import * as React from 'react';
import { useSelector } from 'react-redux';
import { Layout, LayoutProps, AppBar } from 'react-admin';

import Menu from './menu';
import UserProfileMenu from './userProfileMenu.component';

import { AppState } from '../types';
import { darkTheme, lightTheme } from './themes';

const CustomerAppBar = (props: any) => <AppBar key='catptionmaxAppBar' {...props} userMenu={<UserProfileMenu />} />;

const AdminLayout: any = (props: LayoutProps) => {
    const theme = useSelector((state: AppState) =>
        state.theme === 'dark' ? darkTheme : lightTheme
    );
    return <Layout key='captionmaxLayout' {...props} menu={Menu} theme={theme} appBar={CustomerAppBar} />;
};


export default AdminLayout;