import Loadable from 'react-loadable';
import Loading from '../loader/loading.component'

export const AsyncLoadList = Loadable({
    loader: () => import('./eventInfoList.component'),
    loading: Loading
});

export const AsyncLoadCreate = Loadable({
    loader: () => import('./eventInfoCreate.component'),
    loading: Loading
});

export const AsyncLoadEdit = Loadable({
    loader: () => import('./eventInfoEdit.component'),
    loading: Loading
});

export const AsyncLoadShow = Loadable({
    loader: () => import('./eventInfoShow.component'),
    loading: Loading
});

