import Loadable from 'react-loadable';
import Loading from '../loader/loading.component'

const AsyncUserList = Loadable({
    loader: () => import('./userList.component'),
    loading: Loading
});

const AsyncUserEdit = Loadable({
    loader: () => import('./userEdit.component'),
    loading: Loading
});

const AsyncUserCreate = Loadable({
    loader: () => import('./userCreate.component'),
    loading: Loading
});



const UserPages = {
    list: AsyncUserList,
    edit: AsyncUserEdit,
    create: AsyncUserCreate
}

export default UserPages;