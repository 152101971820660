import Loadable from 'react-loadable';
import Loading from '../loader/loading.component'

const AsyncCrontabList = Loadable({
    loader: () => import('./displayOptionList.component'),
    loading: Loading
});

const DisplayOption = {
    list: AsyncCrontabList,
}

export default DisplayOption;