/* eslint-disable */
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItemLink, MenuProps, usePermissions } from 'react-admin';

import SubMenu from './subMenu';

import { AppState, Roles } from '../types';


const Menu = ({ dense = false }: MenuProps) => {
    const { permissions } = usePermissions();

    const [state, setState] = useState({
        menuDisplay: true
    });
    
    useSelector((state: AppState) => state.theme); // force rerender on theme change
    const classes = useStyles();

    type MenuName = 'menuDisplay' ;

    const handleToggle = (menu: MenuName) => {
        setState(state => ({ ...state, [menu]: !state[menu] }));
    };

    return (
        <div className={classes.root}>
            {' '}
            <MenuItemLink
                key='eventMenuLink'
                to={`/event`}
                primaryText={'Session'}
                dense={dense}
            />
            { permissions === Roles.Admin ?
            <SubMenu
                key='displayOptionMenuItem'
                handleToggle={() => handleToggle('menuDisplay')}
                isOpen={state.menuDisplay}
                name="Display Options"
                // icon={<orders.icon />}
                dense={dense}
            >
                <MenuItemLink
                    key='displayOptionsLanguageMenuItem'
                    to={`/languagedisplay`}
                    primaryText={'Language'}
                    // leftIcon={<orders.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    key='displayOptionsFontSizedMenuItem'
                    to={`/fontsize`}
                    primaryText={'Font Size'}
                    // leftIcon={<invoices.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    key='displayOptionsFontFamilyMenuItem'
                    to={`/fontfamily`}
                    primaryText={'Font Family'}
                    // leftIcon={<invoices.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    key='displayOptionsBackgroundMenuItem'
                    to={`/background`}
                    primaryText={'Background'}
                    // leftIcon={<invoices.icon />}
                    dense={dense}
                />
                <MenuItemLink
                    key='displayOptionsTextColorMenuItem'
                    to={`/textcolor`}
                    primaryText={'Text Color'}
                    // leftIcon={<invoices.icon />}
                    dense={dense}
                />
            </SubMenu> : null }
            { permissions === Roles.Admin ?
            <>
                <MenuItemLink
                    key='userMenuLink'
                    to={`/user`}
                    primaryText={'Users'}
                    dense={dense}
                /> 
                <MenuItemLink
                    key='templateMenuLink'
                    to={`/template`}
                    primaryText={'Templates'}
                    dense={dense}
                />
            </> : null}
        </div>
    );
};

const useStyles = makeStyles(theme => ({
    root: {
        marginTop: theme.spacing(1),
    },
}));

export default Menu;
