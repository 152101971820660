import React, { useEffect, useState } from 'react';
import { Admin, Resource, defaultTheme } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { Route } from 'react-router-dom';

import {
    unstable_createMuiStrictModeTheme,
    createTheme,
} from '@material-ui/core/styles';

import { AsyncLoadCreate, AsyncLoadEdit, AsyncLoadList, AsyncLoadShow } from './components/eventInfo/eventInfo.component'
import { AsyncLoadTemplateList, AsyncLoadTemplateCreate, AsyncLoadTemplateEdit } from './components/eventInfo/eventTemplate/template.components';

import DisplayOption from './components/displayOption/displayOption.component';
import CaptionMaxAuthProvider from './authProvider/captionMaxAuthProvider'
import UserPages from './components/user/user.component';
import DashboardRedirect from './dashboardRedirect.component';

import Layout from './layout/layout';
import { fetchJson } from './dataProvider/fetch';
import themeReducer from './themeReducer';

import {  getApiUrl, Resources, Roles } from './types';

// FIXME MUI bug https://github.com/mui-org/material-ui/issues/13394
const theme =
    process.env.NODE_ENV !== 'production'
        ? unstable_createMuiStrictModeTheme(defaultTheme)
        : createTheme(defaultTheme);

const httpClient = (url: string, options: any = {}) => {
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    const token = CaptionMaxAuthProvider.getAuthToken();
    options.headers.set('Authorization', `Bearer ${token}`);
    
    return fetchJson(url, options);
};

const cacheDataProviderProxy = (dataProvider: any, duration =  1 * 60 * 1000) =>
    new Proxy(dataProvider, {
        get: (target, name) => (resource: string, params: any) => {
            if (name === 'getOne' || name === 'getMany' || name === 'getList') {
                return dataProvider[name](resource, params).then((response: any) => {
                    const validUntil = new Date();
                    validUntil.setTime(validUntil.getTime() + duration);
                    response.validUntil = validUntil;
                    return response;
                });
            }
            return dataProvider[name](resource, params);
        },
    });

var customRoutes = [<Route key="userProfile" path="/userProfile/:id?" component={UserPages.edit}  />]

const App: any = () => {

    const [api, setApi] = useState<string>('')
    console.log(`The url is (${api})`);
    const dataProvider = simpleRestProvider(api, httpClient, 'x-Total-Count');

    var cacheDataProvider = cacheDataProviderProxy(dataProvider);

    useEffect(() =>{
        fetch('/api/getApiLocation')
        .then(response => {
            const urlResponse = response.json();
            return urlResponse;
        })
        .then(json => {
            console.log('Setting Storage')
            const url = json.url;
            console.log(`In fetch response url (${url})`)
            if (url === undefined)
                sessionStorage.setItem('url', '');
            else
                sessionStorage.setItem('url', url ?? '');
            
            setApi(getApiUrl());
        })
        .catch(error => {
            console.log(error)
        });
    }, [api]);



    return (
        <>
        { api === '' || api === null ? 
            null :
            <Admin  dataProvider={cacheDataProvider} 
                    layout={Layout} 
                    customReducers={{ theme: themeReducer }}
                    disableTelemetry 
                    theme={theme}
                    customRoutes={customRoutes}
                    authProvider={CaptionMaxAuthProvider}
                    dashboard={DashboardRedirect}>

                { permissions => [
                    <Resource name={Resources.Event} 
                        options={{ label: 'Session'}}
                        list={AsyncLoadList}
                        edit={ permissions !== Roles.ReadOnly ? AsyncLoadEdit : null}
                        create={ permissions !== Roles.ReadOnly? AsyncLoadCreate : null} 
                        show={ AsyncLoadShow }/>,

                    permissions !== Roles.ReadOnly ? <Resource name={Resources.Template}
                        list={AsyncLoadTemplateList}
                        edit={AsyncLoadTemplateEdit}
                        create={AsyncLoadTemplateCreate} /> : null,

                    <Resource name={Resources.Writer} />,
                    <Resource name={Resources.TimeZone} />,
                    <Resource name={Resources.ParsingFormat} />,
                    <Resource name={Resources.Language} />,
                    <Resource name={Resources.Customer} />,
                    <Resource name={Resources.Background} options={{ label: 'Background'}}  {...DisplayOption} />,
                    <Resource name={Resources.FontFamily} options={{ label: 'Font Family'}} {...DisplayOption} />,
                    <Resource name={Resources.FontSize} options={{ label: 'Font Size'}} {...DisplayOption} />,
                    <Resource name={Resources.TextColor} options={{ label: 'Text Color'}} {...DisplayOption} />,
                    <Resource name={Resources.LanguageDisplay} options={{ label: 'Language'}} {...DisplayOption} />,
                    permissions === Roles.Admin ? <Resource name={Resources.User} {...UserPages} /> : <Resource name={Resources.User} edit={UserPages.edit} />,
                    permissions === Roles.Admin ? <Resource name={Resources.Role}  /> : null
                ]}        
            </Admin>

        }
        </>
        
)};

export default App;

